import { FC, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, IconButton, Typography } from '@mui/material';

import { tss } from 'tss-react/mui';

import { serverSelectors } from '../apis/lotus.api';
import { useServersSelector } from '../hooks/useServers';
import { ApplicationList } from './ApplicationList';

const useStyles = tss.create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type ServerProps = {
  id: string;
};

export const Server: FC<ServerProps> = ({ id }) => {
  const [open, setOpen] = useState(false);

  const { classes } = useStyles();

  const server = useServersSelector((serverState) => serverSelectors.selectById(serverState, id));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography>
          {server.description} - <Typography variant='caption'>{server.name}</Typography>
        </Typography>

        <IconButton onClick={() => setOpen((prev) => !prev)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>

      <Collapse in={open} unmountOnExit>
        <ApplicationList serverId={id} />
      </Collapse>
    </div>
  );
};

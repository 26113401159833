import { useFindAllProductsQuery } from '../apis/generated-lotus.api';
import { ProductsState, lotusEndpoints, productAdapter } from '../apis/lotus.api';
import { selectIsAuthenticated } from '../slices/auth.slice';
import { useAppSelector } from './useAppSelector';

export const useFindAllProducts = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return useFindAllProductsQuery(undefined, { skip: !isAuthenticated });
};

export const useProductsSelector = <T extends (products: ProductsState) => ReturnType<T>>(selector: T) => {
  // @ts-expect-error - Redux types bad: https://github.com/reduxjs/redux-toolkit/issues/3989
  return lotusEndpoints.findAllProducts.useQueryState<ReturnType<T>>(undefined, {
    selectFromResult: (result) => selector(result.data ?? productAdapter.getInitialState()),
  });
};

import { FC, PropsWithChildren } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { useAppSelector } from '../hooks/useAppSelector';
import { selectTheme } from '../slices';
import { appThemes } from '../theme';

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useAppSelector(selectTheme);

  return (
    <ThemeProvider theme={appThemes[theme]}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

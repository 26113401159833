import { FC } from 'react';

import { Divider, Paper, Typography } from '@mui/material';

import { tss } from 'tss-react/mui';

import { productsSelectors } from '../apis/lotus.api';
import { useProductsSelector } from '../hooks/useProducts';
import { ServerList } from './ServerList';
import { ServiceNodeSelector } from './ServiceNodeSelector';

const useStyles = tss.create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '33%',
    maxWidth: 600,
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type ProductProps = {
  id: string;
};

export const Product: FC<ProductProps> = ({ id }) => {
  const { classes } = useStyles();

  const product = useProductsSelector((products) => productsSelectors.selectById(products, id));

  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.header}>
        <Typography variant='h4' className={classes.title}>
          {product.name}
        </Typography>

        <ServiceNodeSelector productId={id} />
      </div>

      <Divider />

      <ServerList productId={id} />
    </Paper>
  );
};

import { FC } from 'react';

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { tss } from 'tss-react/mui';

import { serviceNodeSelectors } from '../apis/lotus.api';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectServiceNodeIdsByProductId, useServiceNodesSelector } from '../hooks/useServiceNodes';
import { selectServiceNode, setServiceNode } from '../slices';

const useStyles = tss.create(() => ({
  root: {
    width: 250,
  },
}));

type ServiceNodeSelectorProps = {
  productId: string;
};

const ServiceNodeTitle: FC<{ id: string | null }> = ({ id }) => {
  const serviceNode = useServiceNodesSelector((serviceNodes) =>
    serviceNodeSelectors.selectById(serviceNodes, id ?? ''),
  );

  return serviceNode ? `${serviceNode.name} - ${serviceNode.description}` : 'Select a Service Node';
};

export const ServiceNodeSelector: FC<ServiceNodeSelectorProps> = ({ productId }) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const serviceNode = useAppSelector((state) => selectServiceNode(state, productId));

  const serviceNodeIds = useAppSelector((state) => selectServiceNodeIdsByProductId(state, productId));

  const handleSelect = (event: SelectChangeEvent<string>) => {
    dispatch(setServiceNode({ productId, serviceNodeId: event.target.value }));
  };

  return (
    <FormControl size='small' className={classes.root}>
      <InputLabel>Service Node</InputLabel>

      <Select
        value={serviceNode ?? ''}
        label='Service Node'
        renderValue={(serviceNodeId) => <ServiceNodeTitle id={serviceNodeId} />}
        onChange={handleSelect}
      >
        <MenuItem disabled value=''>
          Select a Service Node
        </MenuItem>

        {serviceNodeIds.map((serviceNodeId) => (
          <MenuItem key={serviceNodeId} value={serviceNodeId}>
            <ServiceNodeTitle id={serviceNodeId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

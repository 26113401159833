import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';

import { lotusMiddleware, lotusReducer, lotusReducerPath } from './apis/lotus.api';
import { authPath, authReducer, dialogPath, dialogReducer, uiPath, uiReducer } from './slices';

const reducers = combineReducers({
  [uiPath]: uiReducer,
  [authPath]: authReducer,
  [dialogPath]: dialogReducer,
  [lotusReducerPath]: lotusReducer,
});

const persistedReducer = persistReducer<ReturnType<typeof reducers>>(
  {
    key: 'xcira-control-center',
    storage,
    whitelist: [uiPath, authPath],
    version: 1,
    migrate: async (state, currentVersion) => {
      const persistedVersion = state?._persist.version;

      if (persistedVersion !== currentVersion) {
        return undefined;
      }

      return state;
    },
  },
  reducers,
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignored cause reasons: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([lotusMiddleware]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type RootState = ReturnType<typeof reducers>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

import { FC } from 'react';

import { AppBar, Toolbar, Typography } from '@mui/material';

import { tss } from 'tss-react/mui';

import { Profile } from './Profile';

const useStyles = tss.create(({ theme }) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
}));

export const Header: FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar variant='dense' className={classes.toolbar}>
          <Typography>Xcira Control Center</Typography>

          <div className={classes.spacer} />

          <Profile />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

import { CSSProperties } from 'react';

import { Theme, ThemeOptions, createTheme } from '@mui/material';

const spacing = 8;

export const drawerWidth = 225;

const sharedThemeProps: ThemeOptions = {
  typography: {
    fontFamily: 'Roboto',
  },
  spacing,
  shape: {
    borderRadius: 5,
  },
  palette: {
    secondary: {
      main: '#dc6d11',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(5px)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: spacing / 3,
          paddingLeft: spacing,
          paddingRight: spacing,
          paddingBottom: spacing / 3,
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  grids: {
    inventory:
      ' 50px' + // Lot #
      ' 50px' + // Group #
      ' 50px' + // Active
      ' 55px' + // Status
      ' 60px' + // Action
      ' 50px' + // Image
      ' 135px' + // Day/Ring
      ' minmax(200px, 1fr)' + // Description
      ' 90px' + // Shoot Price
      ' 160px' + // Serial Number
      ' 100px' + // Usage
      ' 200px' + // Comes With
      ' 200px' + // Notes
      ' 70px' + // Category
      ' 90px' + // Owner Code
      ' 65px' + // Quantity
      ' 40px' + // Unit
      ' 50px' + // Video
      ' 200px' + // Listing GUID
      ' 200px', // Notes
  },
};

const muiLightTheme = createTheme(sharedThemeProps, {
  mode: <L>(light: L) => light,
  palette: {
    mode: 'light',
    common: {
      black: '#0B0D0E',
      white: '#fff',
    },
    primary: {
      main: '#007FFF',
      light: '#66B2FF',
      dark: '#004C99',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      main: '#1AA251',
      light: '#6AE79C',
      dark: '#1AA251',
      contrastText: '#fff',
    },
    warning: {
      main: '#DEA500',
      light: '#FFDC48',
      dark: '#AB6800',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      main: '#EB0014',
      light: '#FF99A2',
      dark: '#C70011',
      contrastText: '#fff',
    },
    text: {
      primary: '#1C2025',
      secondary: '#434D5B',
      tertiary: '#6B7A90',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    // divider: '#E5EAF2',
    primaryDark: {
      main: '#7B91A7',
    },
    gradients: {
      lightGrayRadio: 'radial-gradient(50% 50% at 50% 50%, #F0F7FF 0%, rgba(240, 247, 255, 0.05) 100%)',
      stylizedRadio:
        'linear-gradient(rgba(255 255 255 / 0.3), rgba(255 255 255 / 0.3)), linear-gradient(254.86deg, rgba(194, 224, 255, 0.12) 0%, rgba(194, 224, 255, 0.12) 0%, rgba(255, 255, 255, 0.3) 49.98%, rgba(240, 247, 255, 0.3) 100.95%)',
      linearSubtle:
        'linear-gradient(to top right, rgba(235, 245, 255, 0.3) 40%, rgba(243, 246, 249, 0.2) 100%)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#ababab',
        },
      },
    },
  },
});

const muiDarkTheme = createTheme(sharedThemeProps, {
  mode: <L, D>(_light: L, dark: D) => dark,
  palette: {
    mode: 'dark',
    common: {
      black: '#0B0D0E',
      white: '#fff',
    },
    primary: {
      main: '#3399FF',
      light: '#66B2FF',
      dark: '#004C99',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#29b6f6',
      light: '#4fc3f7',
      dark: '#0288d1',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      main: '#1DB45A',
      light: '#6AE79C',
      dark: '#1AA251',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    warning: {
      main: '#DEA500',
      light: '#FFDC48',
      dark: '#AB6800',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      main: '#EB0014',
      light: '#FF99A2',
      dark: '#C70011',
      contrastText: '#fff',
    },
    grey: {
      '50': '#F3F6F9',
      '100': '#E5EAF2',
      '200': '#DAE2ED',
      '300': '#C7D0DD',
      '400': '#B0B8C4',
      '500': '#9DA8B7',
      '600': '#6B7A90',
      '700': '#434D5B',
      '800': '#303740',
      '900': '#1C2025',
      main: '#434D5B',
      contrastText: '#6B7A90',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },
    text: {
      primary: '#fff',
      secondary: '#B0B8C4',
      tertiary: '#B0B8C4',
      disabled: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    background: {
      default: '#101418',
      paper: 'rgba(20, 26, 31, 0.8)',
    },
    divider: 'rgba(59, 74, 89, 0.75)',
    primaryDark: {
      main: '#7B91A7',
    },
    gradients: {
      lightGrayRadio: 'radial-gradient(50% 50% at 50% 50%, #F0F7FF 0%, rgba(240, 247, 255, 0.05) 100%)',
      stylizedRadio:
        'linear-gradient(rgba(0 0 0 / 0.1), rgba(0 0 0 / 0.1)), linear-gradient(254.86deg, rgba(0, 58, 117, 0.18) 0%, rgba(11, 13, 14, 0.3) 49.98%, rgba(0, 76, 153, 0.21) 100.95%)',
      linearSubtle: 'linear-gradient(to top right, rgba(0, 58, 117, 0.1) 40%, rgba(20, 26, 31, 0.2) 100%)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export const appThemes = {
  light: muiLightTheme,
  dark: muiDarkTheme,
};

export const createOpeningTransition = (theme: Theme, props: string | string[]): string =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });

export const createClosingTransition = (theme: Theme, props: string | string[]): string =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });

export const openedMixin = (theme: Theme): CSSProperties => ({
  width: drawerWidth,
  transition: createOpeningTransition(theme, 'width'),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSProperties => ({
  width: theme.spacing(7),
  transition: createClosingTransition(theme, 'width'),
  overflowX: 'hidden',
});

export type AppTheme = keyof typeof appThemes;

declare module '@mui/material/styles' {
  interface Grids {
    inventory: string;
  }

  interface Theme {
    grids: Grids;
    mode: <L, D>(light: L, dark: D) => L | D;
  }

  interface ThemeOptions {
    grids: Grids;
  }
}

import { useCallback, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { loginThunk, selectUser, setUser } from '../slices/auth.slice';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useLogin = () => {
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const { loginWithPopup, getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    if (user && !currentUser) {
      dispatch(setUser(user));
    }
  }, [currentUser, dispatch, user]);

  return useCallback(() => {
    dispatch(
      loginThunk({
        loginFn: loginWithPopup,
        getTokenFn: getAccessTokenSilently,
      }),
    );
  }, [dispatch, getAccessTokenSilently, loginWithPopup]);
};

import { FC } from 'react';

import { Typography } from '@mui/material';

import { tss } from 'tss-react/mui';

import { useAppSelector } from '../hooks/useAppSelector';
import { selectServerIdsByServiceNodeId } from '../hooks/useServers';
import { selectServiceNode } from '../slices';
import { Server } from './Server';
import { ServiceNodeActions } from './ServiceNodeActions';

const useStyles = tss.create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

type ServerListProps = {
  productId: string;
};

export const ServerList: FC<ServerListProps> = ({ productId }) => {
  const { classes } = useStyles();

  const serviceNodeId = useAppSelector((state) => selectServiceNode(state, productId));

  const serverIds = useAppSelector((state) => selectServerIdsByServiceNodeId(state, serviceNodeId ?? ''));

  return (
    <div className={classes.root}>
      {serviceNodeId ? (
        <>
          <ServiceNodeActions serviceNodeId={serviceNodeId} />

          {serverIds.map((serverId) => (
            <Server key={serverId} id={serverId} />
          ))}
        </>
      ) : (
        <Typography>Select a Service Node above to continue.</Typography>
      )}
    </div>
  );
};

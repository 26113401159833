import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

type DialogType = 'execute-stages' | null;

// If DialogMetadata is undefined, then the metadata property is optional.
type TDialog<T extends DialogType, DialogMetadata> = {
  type: T;
} & (DialogMetadata extends undefined
  ? {
      metadata?: undefined;
    }
  : {
      metadata: DialogMetadata;
    });

type ExecuteStagesDialog = TDialog<'execute-stages', { serviceNodeId: string }>;
type NoDialog = TDialog<null, Record<never, never>>;

type Dialog = ExecuteStagesDialog | NoDialog;

type DialogMetadata<T extends DialogType> = Extract<Dialog, { type: T }>['metadata'];

export type DialogState = {
  open: boolean;
} & Dialog;

export const initialDialogState: DialogState = {
  open: false,
  type: null,
  metadata: {},
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialDialogState,
  reducers: {
    openDialog: (state: Draft<DialogState>, action: PayloadAction<Dialog>) => {
      state.open = true;
      state.type = action.payload.type;
      state.metadata = action.payload.metadata;
    },
    closeDialog: (state: Draft<DialogState>) => {
      state.open = false;
    },
  },
});

export const selectDialogOpen = (state: RootState): boolean => state.dialog.open;
export const selectDialogType = (state: RootState): DialogType => state.dialog.type;
export const selectDialogMetadata = <T extends DialogType>(state: RootState): DialogMetadata<T> =>
  state.dialog.metadata as DialogMetadata<T>;

export const { openDialog, closeDialog } = dialogSlice.actions;

export const { name: dialogPath, reducer: dialogReducer } = dialogSlice;

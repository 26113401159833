import { FC } from 'react';

import { tss } from 'tss-react/mui';

import { productsSelectors } from '../apis/lotus.api';
import { useProductsSelector } from '../hooks/useProducts';
import { Product } from './Product';

const useStyles = tss.create(({ theme }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export const ProductList: FC = () => {
  const { classes } = useStyles();

  const productIds = useProductsSelector((products) => productsSelectors.selectIds(products));

  return (
    <div className={classes.root}>
      {productIds.map((id) => (
        <Product key={id} id={id} />
      ))}
    </div>
  );
};

export function decodeJsonWebToken(accessToken: string) {
  const [header, payload, signature] = accessToken.split('.');

  if (!header || !payload || !signature) {
    console.warn('Invalid access token format');

    return {
      header: {},
      payload: {},
      signature: '',
    };
  }

  try {
    return {
      header: JSON.parse(atob(header)),
      payload: JSON.parse(atob(payload)),
      signature,
    };
  } catch (error) {
    console.warn('Invalid access token format');
    console.error(error);

    return {
      header: {},
      payload: {},
      signature: '',
    };
  }
}

export function getPermissionsFromAccessToken(accessToken: string): string[] {
  const { payload } = decodeJsonWebToken(accessToken);

  if (!payload.permissions) {
    console.warn('No permissions found in access token');

    return [];
  }

  if (!Array.isArray(payload.permissions)) {
    console.warn('Permissions in access token are not in an array');

    return [];
  }

  return payload.permissions;
}

import { FC } from 'react';

import { RestartAlt } from '@mui/icons-material';
import { Button } from '@mui/material';

import { useAppDispatch } from '../hooks/useAppDispatch';
import { openDialog } from '../slices';

type ServiceNodeActionsProps = {
  serviceNodeId: string;
};

export const ServiceNodeActions: FC<ServiceNodeActionsProps> = ({ serviceNodeId }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      openDialog({
        type: 'execute-stages',
        metadata: { serviceNodeId },
      }),
    );
  };

  return (
    <Button variant='contained' color='info' startIcon={<RestartAlt />} onClick={handleClick}>
      Restart
    </Button>
  );
};

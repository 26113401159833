import { FC } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';

import { AppThemeProvider } from './components/AppThemeProvider';
import { store } from './store';
import { SimpleView } from './views/SimpleView';

export const App: FC = () => {
  return (
    <Auth0Provider
      clientId='W4BE8uaJ7T5MPBJbFDdcW1z34KouL4G2'
      domain='prod-c6n4fcae.us.auth0.com'
      issuer='https://prod-c6n4fcae.us.auth0.com/'
      authorizationParams={{
        audience: 'https://lotus.xcira.cloud',
        redirect_uri: window.location.origin,
      }}
    >
      <Provider store={store}>
        <AppThemeProvider>
          <SimpleView />
        </AppThemeProvider>
      </Provider>
    </Auth0Provider>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { useFindAllServiceNodesQuery } from '../apis/generated-lotus.api';
import {
  ServiceNodeState,
  lotusEndpoints,
  serviceNodeAdapter,
  serviceNodeSelectors,
} from '../apis/lotus.api';
import { selectIsAuthenticated } from '../slices/auth.slice';
import { RootState } from '../store';
import { useAppSelector } from './useAppSelector';

export const useFindAllServiceNodes = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return useFindAllServiceNodesQuery(undefined, { skip: !isAuthenticated });
};

export const useServiceNodesSelector = <T extends (products: ServiceNodeState) => ReturnType<T>>(
  selector: T,
) => {
  // @ts-expect-error - Redux types bad: https://github.com/reduxjs/redux-toolkit/issues/3989
  return lotusEndpoints.findAllServiceNodes.useQueryState<ReturnType<T>>(undefined, {
    selectFromResult: (result) => selector(result.data ?? serviceNodeAdapter.getInitialState()),
  });
};

export const selectServiceNodes = lotusEndpoints.findAllServiceNodes.select(undefined);

export const selectServiceNodeIdsByProductId = createSelector(
  (state: RootState) => selectServiceNodes(state).data,
  (_state: RootState, productId: string) => productId,
  (serviceNodeState, productId) => {
    if (!serviceNodeState) {
      return [];
    }

    return serviceNodeSelectors
      .selectAll(serviceNodeState)
      .filter((serviceNode) => serviceNode.productId === productId)
      .map((serviceNode) => serviceNode.id);
  },
);

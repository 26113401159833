import { FC } from 'react';

import { RestartAlt } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useExecuteStagesMutation } from '../../apis/generated-lotus.api';
import { serviceNodeSelectors } from '../../apis/lotus.api';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useServiceNodesSelector } from '../../hooks/useServiceNodes';
import { closeDialog, selectDialogMetadata, selectDialogOpen } from '../../slices';

export const ExecuteStagesDialog: FC = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector(selectDialogOpen);
  const { serviceNodeId } = useAppSelector(selectDialogMetadata<'execute-stages'>);

  const serviceNode = useServiceNodesSelector((serviceNodes) =>
    serviceNodeSelectors.selectById(serviceNodes, serviceNodeId),
  );

  const [executeStages] = useExecuteStagesMutation();

  const handleSubmit = async () => {
    await executeStages({ id: serviceNodeId });

    dispatch(closeDialog());
  };

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure you want to bounce {serviceNode.name}?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          You are about to bounce{' '}
          <b>
            {serviceNode.name} - {serviceNode.description}
          </b>
          . This action will restart all the applications in the service node and may affect ongoing sales.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={handleCancel}>
          Cancel
        </Button>

        <Button variant='outlined' color='error' startIcon={<RestartAlt />} onClick={handleSubmit}>
          Bounce
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { useFindAllApplicationsQuery } from '../apis/generated-lotus.api';
import {
  ApplicationState,
  applicationAdapter,
  applicationSelectors,
  lotusEndpoints,
} from '../apis/lotus.api';
import { selectIsAuthenticated } from '../slices/auth.slice';
import { RootState } from '../store';
import { useAppSelector } from './useAppSelector';

export const useFindAllApplications = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return useFindAllApplicationsQuery(undefined, { skip: !isAuthenticated });
};

export const useApplicationsSelector = <T extends (products: ApplicationState) => ReturnType<T>>(
  selector: T,
) => {
  // @ts-expect-error - Redux types bad: https://github.com/reduxjs/redux-toolkit/issues/3989
  return lotusEndpoints.findAllApplications.useQueryState<ReturnType<T>>(undefined, {
    selectFromResult: (result) => selector(result.data ?? applicationAdapter.getInitialState()),
  });
};

export const selectApplications = lotusEndpoints.findAllApplications.select(undefined);

export const selectApplicationIdsByServerId = createSelector(
  (state: RootState) => selectApplications(state).data,
  (_state: RootState, serverId: string) => serverId,
  (applicationState, serverId) => {
    if (!applicationState) {
      return [];
    }

    return applicationSelectors
      .selectAll(applicationState)
      .filter((application) => application.serverId === serverId)
      .map((application) => application.id);
  },
);

import { FC } from 'react';

import { Typography } from '@mui/material';

import { tss } from 'tss-react/mui';

import { applicationSelectors } from '../apis/lotus.api';
import { useApplicationsSelector } from '../hooks/useApplications';

const useStyles = tss.create(({ theme }) => ({
  root: {
    dispaly: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius / 2,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

type ApplicationProps = {
  id: string;
};

export const Application: FC<ApplicationProps> = ({ id }) => {
  const { classes } = useStyles();

  const application = useApplicationsSelector((applications) =>
    applicationSelectors.selectById(applications, id),
  );

  return (
    <div className={classes.root}>
      <Typography variant='subtitle2'>
        Name: <Typography variant='caption'>{application.name}</Typography>
      </Typography>

      <Typography variant='subtitle2'>
        Description: <Typography variant='caption'>{application.description}</Typography>
      </Typography>

      <Typography variant='subtitle2'>
        Status: <Typography variant='caption'>{application.status}</Typography>
      </Typography>
    </div>
  );
};

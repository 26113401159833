import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { logoutThunk } from '../slices/auth.slice';
import { useAppDispatch } from './useAppDispatch';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth0();

  return useCallback(() => {
    dispatch(
      logoutThunk({
        logoutFn: () =>
          logout({
            openUrl: () => {
              window.location.replace(window.location.origin);
            },
          }),
      }),
    );
  }, [dispatch, logout]);
};

import { emptyLotusApi as api } from './empty-lotus.api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createProduct: build.mutation<CreateProductApiResponse, CreateProductApiArg>({
      query: (queryArg) => ({ url: `/api/products`, method: 'POST', body: queryArg.createProductDto }),
    }),
    findAllProducts: build.query<FindAllProductsApiResponse, FindAllProductsApiArg>({
      query: () => ({ url: `/api/products` }),
    }),
    searchProducts: build.query<SearchProductsApiResponse, SearchProductsApiArg>({
      query: (queryArg) => ({
        url: `/api/products/search`,
        params: {
          createdOn: queryArg.createdOn,
          createdBy: queryArg.createdBy,
          updatedOn: queryArg.updatedOn,
          updatedBy: queryArg.updatedBy,
          id: queryArg.id,
          name: queryArg.name,
          requiredRole: queryArg.requiredRole,
        },
      }),
    }),
    findOneProduct: build.query<FindOneProductApiResponse, FindOneProductApiArg>({
      query: (queryArg) => ({ url: `/api/products/${queryArg.id}` }),
    }),
    updateProduct: build.mutation<UpdateProductApiResponse, UpdateProductApiArg>({
      query: (queryArg) => ({
        url: `/api/products/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateProductDto,
      }),
    }),
    removeProduct: build.mutation<RemoveProductApiResponse, RemoveProductApiArg>({
      query: (queryArg) => ({ url: `/api/products/${queryArg.id}`, method: 'DELETE' }),
    }),
    createServiceNode: build.mutation<CreateServiceNodeApiResponse, CreateServiceNodeApiArg>({
      query: (queryArg) => ({
        url: `/api/service-nodes`,
        method: 'POST',
        body: queryArg.createServiceNodeDto,
      }),
    }),
    findAllServiceNodes: build.query<FindAllServiceNodesApiResponse, FindAllServiceNodesApiArg>({
      query: () => ({ url: `/api/service-nodes` }),
    }),
    searchServiceNodes: build.query<SearchServiceNodesApiResponse, SearchServiceNodesApiArg>({
      query: (queryArg) => ({
        url: `/api/service-nodes/search`,
        params: {
          createdOn: queryArg.createdOn,
          createdBy: queryArg.createdBy,
          updatedOn: queryArg.updatedOn,
          updatedBy: queryArg.updatedBy,
          id: queryArg.id,
          productId: queryArg.productId,
          name: queryArg.name,
          description: queryArg.description,
          type: queryArg['type'],
        },
      }),
    }),
    findOneServiceNode: build.query<FindOneServiceNodeApiResponse, FindOneServiceNodeApiArg>({
      query: (queryArg) => ({ url: `/api/service-nodes/${queryArg.id}` }),
    }),
    updateServiceNode: build.mutation<UpdateServiceNodeApiResponse, UpdateServiceNodeApiArg>({
      query: (queryArg) => ({
        url: `/api/service-nodes/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateServiceNodeDto,
      }),
    }),
    removeServiceNode: build.mutation<RemoveServiceNodeApiResponse, RemoveServiceNodeApiArg>({
      query: (queryArg) => ({ url: `/api/service-nodes/${queryArg.id}`, method: 'DELETE' }),
    }),
    executeStages: build.mutation<ExecuteStagesApiResponse, ExecuteStagesApiArg>({
      query: (queryArg) => ({ url: `/api/service-nodes/${queryArg.id}/execute-stages`, method: 'PUT' }),
    }),
    createApplication: build.mutation<CreateApplicationApiResponse, CreateApplicationApiArg>({
      query: (queryArg) => ({
        url: `/api/applications`,
        method: 'POST',
        body: queryArg.createApplicationDto,
      }),
    }),
    findAllApplications: build.query<FindAllApplicationsApiResponse, FindAllApplicationsApiArg>({
      query: () => ({ url: `/api/applications` }),
    }),
    searchApplications: build.query<SearchApplicationsApiResponse, SearchApplicationsApiArg>({
      query: (queryArg) => ({
        url: `/api/applications/search`,
        params: {
          createdOn: queryArg.createdOn,
          createdBy: queryArg.createdBy,
          updatedOn: queryArg.updatedOn,
          updatedBy: queryArg.updatedBy,
          id: queryArg.id,
          serverId: queryArg.serverId,
          executorId: queryArg.executorId,
          name: queryArg.name,
          description: queryArg.description,
          status: queryArg.status,
        },
      }),
    }),
    findOneApplication: build.query<FindOneApplicationApiResponse, FindOneApplicationApiArg>({
      query: (queryArg) => ({ url: `/api/applications/${queryArg.id}` }),
    }),
    updateApplication: build.mutation<UpdateApplicationApiResponse, UpdateApplicationApiArg>({
      query: (queryArg) => ({
        url: `/api/applications/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateApplicationDto,
      }),
    }),
    removeApplication: build.mutation<RemoveApplicationApiResponse, RemoveApplicationApiArg>({
      query: (queryArg) => ({ url: `/api/applications/${queryArg.id}`, method: 'DELETE' }),
    }),
    createExecutor: build.mutation<CreateExecutorApiResponse, CreateExecutorApiArg>({
      query: (queryArg) => ({ url: `/api/executors`, method: 'POST', body: queryArg.createExecutorDto }),
    }),
    findAllExecutors: build.query<FindAllExecutorsApiResponse, FindAllExecutorsApiArg>({
      query: () => ({ url: `/api/executors` }),
    }),
    searchExecutors: build.query<SearchExecutorsApiResponse, SearchExecutorsApiArg>({
      query: (queryArg) => ({
        url: `/api/executors/search`,
        params: {
          createdOn: queryArg.createdOn,
          createdBy: queryArg.createdBy,
          updatedOn: queryArg.updatedOn,
          updatedBy: queryArg.updatedBy,
          id: queryArg.id,
          name: queryArg.name,
          description: queryArg.description,
          variant: queryArg.variant,
          entryPoint: queryArg.entryPoint,
        },
      }),
    }),
    findOneExecutor: build.query<FindOneExecutorApiResponse, FindOneExecutorApiArg>({
      query: (queryArg) => ({ url: `/api/executors/${queryArg.id}` }),
    }),
    updateExecutor: build.mutation<UpdateExecutorApiResponse, UpdateExecutorApiArg>({
      query: (queryArg) => ({
        url: `/api/executors/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateExecutorDto,
      }),
    }),
    removeExecutor: build.mutation<RemoveExecutorApiResponse, RemoveExecutorApiArg>({
      query: (queryArg) => ({ url: `/api/executors/${queryArg.id}`, method: 'DELETE' }),
    }),
    createServer: build.mutation<CreateServerApiResponse, CreateServerApiArg>({
      query: (queryArg) => ({ url: `/api/servers`, method: 'POST', body: queryArg.createServerDto }),
    }),
    findAllServers: build.query<FindAllServersApiResponse, FindAllServersApiArg>({
      query: () => ({ url: `/api/servers` }),
    }),
    searchServers: build.query<SearchServersApiResponse, SearchServersApiArg>({
      query: (queryArg) => ({
        url: `/api/servers/search`,
        params: {
          createdOn: queryArg.createdOn,
          createdBy: queryArg.createdBy,
          updatedOn: queryArg.updatedOn,
          updatedBy: queryArg.updatedBy,
          id: queryArg.id,
          serviceNodeId: queryArg.serviceNodeId,
          name: queryArg.name,
          description: queryArg.description,
          hostname: queryArg.hostname,
        },
      }),
    }),
    findOneServer: build.query<FindOneServerApiResponse, FindOneServerApiArg>({
      query: (queryArg) => ({ url: `/api/servers/${queryArg.id}` }),
    }),
    updateServer: build.mutation<UpdateServerApiResponse, UpdateServerApiArg>({
      query: (queryArg) => ({
        url: `/api/servers/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateServerDto,
      }),
    }),
    removeServer: build.mutation<RemoveServerApiResponse, RemoveServerApiArg>({
      query: (queryArg) => ({ url: `/api/servers/${queryArg.id}`, method: 'DELETE' }),
    }),
    createStage: build.mutation<CreateStageApiResponse, CreateStageApiArg>({
      query: (queryArg) => ({ url: `/api/stages`, method: 'POST', body: queryArg.createStageDto }),
    }),
    findAllStages: build.query<FindAllStagesApiResponse, FindAllStagesApiArg>({
      query: () => ({ url: `/api/stages` }),
    }),
    searchStages: build.query<SearchStagesApiResponse, SearchStagesApiArg>({
      query: (queryArg) => ({
        url: `/api/stages/search`,
        params: {
          createdOn: queryArg.createdOn,
          createdBy: queryArg.createdBy,
          updatedOn: queryArg.updatedOn,
          updatedBy: queryArg.updatedBy,
          id: queryArg.id,
          serviceNodeId: queryArg.serviceNodeId,
          name: queryArg.name,
          description: queryArg.description,
          enabled: queryArg.enabled,
          status: queryArg.status,
          order: queryArg.order,
          action: queryArg.action,
          applicationIds: queryArg.applicationIds,
          lastRanOn: queryArg.lastRanOn,
          lastRanBy: queryArg.lastRanBy,
        },
      }),
    }),
    findOneStage: build.query<FindOneStageApiResponse, FindOneStageApiArg>({
      query: (queryArg) => ({ url: `/api/stages/${queryArg.id}` }),
    }),
    updateStage: build.mutation<UpdateStageApiResponse, UpdateStageApiArg>({
      query: (queryArg) => ({
        url: `/api/stages/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateStageDto,
      }),
    }),
    removeStage: build.mutation<RemoveStageApiResponse, RemoveStageApiArg>({
      query: (queryArg) => ({ url: `/api/stages/${queryArg.id}`, method: 'DELETE' }),
    }),
    subscribe: build.query<SubscribeApiResponse, SubscribeApiArg>({
      query: () => ({ url: `/api/events/subscribe` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedLotusApi };
export type CreateProductApiResponse = /** status 201  */ Product;
export type CreateProductApiArg = {
  createProductDto: CreateProductDto;
};
export type FindAllProductsApiResponse = /** status 200  */ Product[];
export type FindAllProductsApiArg = void;
export type SearchProductsApiResponse = /** status 200  */ Product[];
export type SearchProductsApiArg = {
  /** The UTC timestamp when the Resource was created. */
  createdOn?: number;
  /** The username and email of the user who created the Resource. */
  createdBy?: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn?: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy?: string;
  /** The unique identifier of the Product. */
  id?: string;
  /** The name of the Product. */
  name?: string;
  /** The role required to bounce Service Nodes in the Product. */
  requiredRole?: string;
};
export type FindOneProductApiResponse = /** status 200  */ Product;
export type FindOneProductApiArg = {
  id: string;
};
export type UpdateProductApiResponse = /** status 200  */ Product;
export type UpdateProductApiArg = {
  id: string;
  updateProductDto: UpdateProductDto;
};
export type RemoveProductApiResponse = /** status 200  */ Alert;
export type RemoveProductApiArg = {
  id: string;
};
export type CreateServiceNodeApiResponse = /** status 201  */ ServiceNode;
export type CreateServiceNodeApiArg = {
  createServiceNodeDto: CreateServiceNodeDto;
};
export type FindAllServiceNodesApiResponse = /** status 200  */ ServiceNode[];
export type FindAllServiceNodesApiArg = void;
export type SearchServiceNodesApiResponse = /** status 200  */ ServiceNode[];
export type SearchServiceNodesApiArg = {
  /** The UTC timestamp when the Resource was created. */
  createdOn?: number;
  /** The username and email of the user who created the Resource. */
  createdBy?: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn?: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy?: string;
  /** The unique identifier of the Service Node. */
  id?: string;
  /** The unique identifier of the Product. */
  productId?: string;
  /** The name of the Service Node. */
  name?: string;
  /** The description of the Service Node. */
  description?: string;
  /** The type of the Service Node. */
  type?: ServiceNodeType;
};
export type FindOneServiceNodeApiResponse = /** status 200  */ ServiceNode;
export type FindOneServiceNodeApiArg = {
  id: string;
};
export type UpdateServiceNodeApiResponse = /** status 200  */ ServiceNode;
export type UpdateServiceNodeApiArg = {
  id: string;
  updateServiceNodeDto: UpdateServiceNodeDto;
};
export type RemoveServiceNodeApiResponse = /** status 200  */ Alert;
export type RemoveServiceNodeApiArg = {
  id: string;
};
export type ExecuteStagesApiResponse = /** status 200  */ Alert;
export type ExecuteStagesApiArg = {
  id: string;
};
export type CreateApplicationApiResponse = /** status 201  */ Application;
export type CreateApplicationApiArg = {
  createApplicationDto: CreateApplicationDto;
};
export type FindAllApplicationsApiResponse = /** status 200  */ Application[];
export type FindAllApplicationsApiArg = void;
export type SearchApplicationsApiResponse = /** status 200  */ Application[];
export type SearchApplicationsApiArg = {
  /** The UTC timestamp when the Resource was created. */
  createdOn?: number;
  /** The username and email of the user who created the Resource. */
  createdBy?: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn?: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy?: string;
  /** The unique identifier of the Application. */
  id?: string;
  /** The unique identifier of the Server the Application runs on. */
  serverId?: string;
  /** The unique identifier of the Executor that operates on the Application. */
  executorId?: string;
  /** The name of the Application process. */
  name?: string;
  /** The description of the Application. */
  description?: string;
  /** The status of the Application. */
  status?: ApplicationStatus;
};
export type FindOneApplicationApiResponse = /** status 200  */ Application;
export type FindOneApplicationApiArg = {
  id: string;
};
export type UpdateApplicationApiResponse = /** status 200  */ Application;
export type UpdateApplicationApiArg = {
  id: string;
  updateApplicationDto: UpdateApplicationDto;
};
export type RemoveApplicationApiResponse = /** status 200  */ Alert;
export type RemoveApplicationApiArg = {
  id: string;
};
export type CreateExecutorApiResponse = /** status 201  */ Executor;
export type CreateExecutorApiArg = {
  createExecutorDto: CreateExecutorDto;
};
export type FindAllExecutorsApiResponse = /** status 200  */ Executor[];
export type FindAllExecutorsApiArg = void;
export type SearchExecutorsApiResponse = /** status 200  */ Executor[];
export type SearchExecutorsApiArg = {
  /** The UTC timestamp when the Resource was created. */
  createdOn?: number;
  /** The username and email of the user who created the Resource. */
  createdBy?: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn?: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy?: string;
  /** The unique identifier of the Executor. */
  id?: string;
  /** The name of the Executor. */
  name?: string;
  /** The description of the Executor. */
  description?: string;
  /** The variant of the Executor. */
  variant?: ExecutorVariant;
  /** The entry point of the Executor. This is the path to the script or command that will be executed. */
  entryPoint?: string;
};
export type FindOneExecutorApiResponse = /** status 200  */ Executor;
export type FindOneExecutorApiArg = {
  id: string;
};
export type UpdateExecutorApiResponse = /** status 200  */ Executor;
export type UpdateExecutorApiArg = {
  id: string;
  updateExecutorDto: UpdateExecutorDto;
};
export type RemoveExecutorApiResponse = /** status 200  */ Alert;
export type RemoveExecutorApiArg = {
  id: string;
};
export type CreateServerApiResponse = /** status 201  */ Server;
export type CreateServerApiArg = {
  createServerDto: CreateServerDto;
};
export type FindAllServersApiResponse = /** status 200  */ Server[];
export type FindAllServersApiArg = void;
export type SearchServersApiResponse = /** status 200  */ Server[];
export type SearchServersApiArg = {
  /** The UTC timestamp when the Resource was created. */
  createdOn?: number;
  /** The username and email of the user who created the Resource. */
  createdBy?: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn?: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy?: string;
  /** The unique identifier of the Server. */
  id?: string;
  /** The unique identifier of the Service Node. */
  serviceNodeId?: string;
  /** The short name of the Server. */
  name?: string;
  /** The description of the Server. */
  description?: string;
  /** The hostname of the Server. */
  hostname?: string;
};
export type FindOneServerApiResponse = /** status 200  */ Server;
export type FindOneServerApiArg = {
  id: string;
};
export type UpdateServerApiResponse = /** status 200  */ Server;
export type UpdateServerApiArg = {
  id: string;
  updateServerDto: UpdateServerDto;
};
export type RemoveServerApiResponse = /** status 200  */ Alert;
export type RemoveServerApiArg = {
  id: string;
};
export type CreateStageApiResponse = /** status 201  */ Stage;
export type CreateStageApiArg = {
  createStageDto: CreateStageDto;
};
export type FindAllStagesApiResponse = /** status 200  */ Stage[];
export type FindAllStagesApiArg = void;
export type SearchStagesApiResponse = /** status 200  */ Stage[];
export type SearchStagesApiArg = {
  /** The UTC timestamp when the Resource was created. */
  createdOn?: number;
  /** The username and email of the user who created the Resource. */
  createdBy?: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn?: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy?: string;
  /** The unique identifier of the Stage. */
  id?: string;
  /** The unique identifier of the Service Node that the Stage belongs to. */
  serviceNodeId?: string;
  /** The name of the Stage. */
  name?: string;
  /** The description of the Stage. */
  description?: string;
  /** Whether the Stage is enabled or not. */
  enabled?: boolean;
  /** The status of the Stage. */
  status?: StageStatus;
  /** The order in which the Stage should be executed. */
  order?: number;
  /** The action that the Stage should perform. */
  action?: StageAction;
  /** An array of unique identifiers of the Applications this Stage should be applied to. */
  applicationIds?: string[];
  /** The date and time the Stage was last executed. */
  lastRanOn?: string;
  /** The user who last executed the Stage. */
  lastRanBy?: string;
};
export type FindOneStageApiResponse = /** status 200  */ Stage;
export type FindOneStageApiArg = {
  id: string;
};
export type UpdateStageApiResponse = /** status 200  */ Stage;
export type UpdateStageApiArg = {
  id: string;
  updateStageDto: UpdateStageDto;
};
export type RemoveStageApiResponse = /** status 200  */ Alert;
export type RemoveStageApiArg = {
  id: string;
};
export type SubscribeApiResponse = /** status 200  */ object;
export type SubscribeApiArg = void;
export type Product = {
  /** The UTC timestamp when the Resource was created. */
  createdOn: number;
  /** The username and email of the user who created the Resource. */
  createdBy: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy: string;
  /** The unique identifier of the Product. */
  id: string;
  /** The name of the Product. */
  name: string;
  /** The role required to bounce Service Nodes in the Product. */
  requiredRole: string;
};
export type CreateProductDto = {
  /** The name of the Product. */
  name: string;
  /** The role required to bounce Service Nodes in the Product. */
  requiredRole: string;
};
export type UpdateProductDto = {
  /** The name of the Product. */
  name?: string;
  /** The role required to bounce Service Nodes in the Product. */
  requiredRole?: string;
};
export type Alert = {
  severity: AlertSeverity;
  /** The message of the Alert. */
  message: string;
  /** The timestamp when the Alert was created. */
  timestamp: number;
};
export type ServiceNode = {
  /** The UTC timestamp when the Resource was created. */
  createdOn: number;
  /** The username and email of the user who created the Resource. */
  createdBy: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy: string;
  /** The unique identifier of the Service Node. */
  id: string;
  /** The unique identifier of the Product. */
  productId: string;
  /** The name of the Service Node. */
  name: string;
  /** The description of the Service Node. */
  description: string;
  type: ServiceNodeType;
};
export type CreateServiceNodeDto = {
  /** The unique identifier of the Product. */
  productId: string;
  /** The name of the Service Node. */
  name: string;
  /** The description of the Service Node. */
  description: string;
  type: ServiceNodeType;
};
export type UpdateServiceNodeDto = {
  /** The unique identifier of the Product. */
  productId?: string;
  /** The name of the Service Node. */
  name?: string;
  /** The description of the Service Node. */
  description?: string;
  type?: ServiceNodeType;
};
export type Application = {
  /** The UTC timestamp when the Resource was created. */
  createdOn: number;
  /** The username and email of the user who created the Resource. */
  createdBy: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy: string;
  /** The unique identifier of the Application. */
  id: string;
  /** The unique identifier of the Server the Application runs on. */
  serverId: string;
  /** The unique identifier of the Executor that operates on the Application. */
  executorId: string;
  /** The name of the Application process. */
  name: string;
  /** The description of the Application. */
  description: string;
  status: ApplicationStatus;
  /** The arguments to pass to the Executor. */
  executorArguments: object;
};
export type CreateApplicationDto = {
  /** The unique identifier of the Server the Application runs on. */
  serverId: string;
  /** The unique identifier of the Executor that operates on the Application. */
  executorId: string;
  /** The name of the Application process. */
  name: string;
  /** The description of the Application. */
  description: string;
  status: ApplicationStatus;
  /** The arguments to pass to the Executor. */
  executorArguments: object;
};
export type UpdateApplicationDto = {
  /** The unique identifier of the Server the Application runs on. */
  serverId?: string;
  /** The unique identifier of the Executor that operates on the Application. */
  executorId?: string;
  /** The name of the Application process. */
  name?: string;
  /** The description of the Application. */
  description?: string;
  status?: ApplicationStatus;
  /** The arguments to pass to the Executor. */
  executorArguments?: object;
};
export type Executor = {
  /** The UTC timestamp when the Resource was created. */
  createdOn: number;
  /** The username and email of the user who created the Resource. */
  createdBy: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy: string;
  /** The unique identifier of the Executor. */
  id: string;
  /** The name of the Executor. */
  name: string;
  /** The description of the Executor. */
  description: string;
  variant: ExecutorVariant;
  /** The entry point of the Executor. This is the path to the script or command that will be executed. */
  entryPoint: string;
};
export type CreateExecutorDto = {
  /** The name of the Executor. */
  name: string;
  /** The description of the Executor. */
  description: string;
  variant: ExecutorVariant;
  /** The entry point of the Executor. This is the path to the script or command that will be executed. */
  entryPoint: string;
};
export type UpdateExecutorDto = {
  /** The name of the Executor. */
  name?: string;
  /** The description of the Executor. */
  description?: string;
  variant?: ExecutorVariant;
  /** The entry point of the Executor. This is the path to the script or command that will be executed. */
  entryPoint?: string;
};
export type Server = {
  /** The UTC timestamp when the Resource was created. */
  createdOn: number;
  /** The username and email of the user who created the Resource. */
  createdBy: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy: string;
  /** The unique identifier of the Server. */
  id: string;
  /** The unique identifier of the Service Node. */
  serviceNodeId: string;
  /** The short name of the Server. */
  name: string;
  /** The description of the Server. */
  description: string;
  /** The hostname of the Server. */
  hostname: string;
};
export type CreateServerDto = {
  /** The unique identifier of the Service Node. */
  serviceNodeId: string;
  /** The short name of the Server. */
  name: string;
  /** The description of the Server. */
  description: string;
  /** The hostname of the Server. */
  hostname: string;
};
export type UpdateServerDto = {
  /** The unique identifier of the Service Node. */
  serviceNodeId?: string;
  /** The short name of the Server. */
  name?: string;
  /** The description of the Server. */
  description?: string;
  /** The hostname of the Server. */
  hostname?: string;
};
export type Stage = {
  /** The UTC timestamp when the Resource was created. */
  createdOn: number;
  /** The username and email of the user who created the Resource. */
  createdBy: string;
  /** The UTC timestamp when the Resource was last updated. */
  updatedOn: number;
  /** The username and email of the user who last updated the Resource. */
  updatedBy: string;
  /** The unique identifier of the Stage. */
  id: string;
  /** The unique identifier of the Service Node that the Stage belongs to. */
  serviceNodeId: string;
  /** The name of the Stage. */
  name: string;
  /** The description of the Stage. */
  description: string;
  /** Whether the Stage is enabled or not. */
  enabled: boolean;
  status: StageStatus;
  /** The order in which the Stage should be executed. */
  order: number;
  action: StageAction;
  /** An array of unique identifiers of the Applications this Stage should be applied to. */
  applicationIds: string[];
  /** The date and time the Stage was last executed. */
  lastRanOn: string;
  /** The user who last executed the Stage. */
  lastRanBy: string;
};
export type CreateStageDto = {
  /** The unique identifier of the Service Node that the Stage belongs to. */
  serviceNodeId: string;
  /** The name of the Stage. */
  name: string;
  /** The description of the Stage. */
  description: string;
  /** Whether the Stage is enabled or not. */
  enabled: boolean;
  /** The order in which the Stage should be executed. */
  order: number;
  action: StageAction;
  /** An array of unique identifiers of the Applications this Stage should be applied to. */
  applicationIds: string[];
  /** The date and time the Stage was last executed. */
  lastRanOn: string;
  /** The user who last executed the Stage. */
  lastRanBy: string;
};
export type UpdateStageDto = {
  /** The unique identifier of the Service Node that the Stage belongs to. */
  serviceNodeId?: string;
  /** The name of the Stage. */
  name?: string;
  /** The description of the Stage. */
  description?: string;
  /** Whether the Stage is enabled or not. */
  enabled?: boolean;
  /** The order in which the Stage should be executed. */
  order?: number;
  action?: StageAction;
  /** An array of unique identifiers of the Applications this Stage should be applied to. */
  applicationIds?: string[];
  /** The date and time the Stage was last executed. */
  lastRanOn?: string;
  /** The user who last executed the Stage. */
  lastRanBy?: string;
};
export enum AlertSeverity {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}
export enum ServiceNodeType {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}
export enum ApplicationStatus {
  Running = 'running',
  Stopped = 'stopped',
  Unknown = 'unknown',
}
export enum ExecutorVariant {
  Script = 'script',
  Command = 'command',
}
export enum StageStatus {
  Running = 'running',
  Stopped = 'stopped',
  Failed = 'failed',
}
export enum StageAction {
  Start = 'start',
  Stop = 'stop',
  Restart = 'restart',
  Status = 'status',
}
export const {
  useCreateProductMutation,
  useFindAllProductsQuery,
  useLazyFindAllProductsQuery,
  useSearchProductsQuery,
  useLazySearchProductsQuery,
  useFindOneProductQuery,
  useLazyFindOneProductQuery,
  useUpdateProductMutation,
  useRemoveProductMutation,
  useCreateServiceNodeMutation,
  useFindAllServiceNodesQuery,
  useLazyFindAllServiceNodesQuery,
  useSearchServiceNodesQuery,
  useLazySearchServiceNodesQuery,
  useFindOneServiceNodeQuery,
  useLazyFindOneServiceNodeQuery,
  useUpdateServiceNodeMutation,
  useRemoveServiceNodeMutation,
  useExecuteStagesMutation,
  useCreateApplicationMutation,
  useFindAllApplicationsQuery,
  useLazyFindAllApplicationsQuery,
  useSearchApplicationsQuery,
  useLazySearchApplicationsQuery,
  useFindOneApplicationQuery,
  useLazyFindOneApplicationQuery,
  useUpdateApplicationMutation,
  useRemoveApplicationMutation,
  useCreateExecutorMutation,
  useFindAllExecutorsQuery,
  useLazyFindAllExecutorsQuery,
  useSearchExecutorsQuery,
  useLazySearchExecutorsQuery,
  useFindOneExecutorQuery,
  useLazyFindOneExecutorQuery,
  useUpdateExecutorMutation,
  useRemoveExecutorMutation,
  useCreateServerMutation,
  useFindAllServersQuery,
  useLazyFindAllServersQuery,
  useSearchServersQuery,
  useLazySearchServersQuery,
  useFindOneServerQuery,
  useLazyFindOneServerQuery,
  useUpdateServerMutation,
  useRemoveServerMutation,
  useCreateStageMutation,
  useFindAllStagesQuery,
  useLazyFindAllStagesQuery,
  useSearchStagesQuery,
  useLazySearchStagesQuery,
  useFindOneStageQuery,
  useLazyFindOneStageQuery,
  useUpdateStageMutation,
  useRemoveStageMutation,
  useSubscribeQuery,
  useLazySubscribeQuery,
} = injectedRtkApi;

import { FC } from 'react';

import { tss } from 'tss-react/mui';

import { AppDialog } from '../components/AppDialog';
import { Header } from '../components/Header';
import { ProductList } from '../components/ProductList';
import { ApplicationsProvider } from '../providers/ApplicationsProvider';
import { ProductsProvider } from '../providers/ProductsProvider';
import { ServersProvider } from '../providers/ServersProvider';
import { ServiceNodesProvider } from '../providers/ServiceNodesProvider';

const useStyles = tss.create(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
}));

export const SimpleView: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <ProductsProvider />
      <ServiceNodesProvider />
      <ServersProvider />
      <ApplicationsProvider />

      <AppDialog />

      <Header />

      <ProductList />
    </div>
  );
};

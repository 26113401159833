import { FC } from 'react';

import { useAppSelector } from '../hooks/useAppSelector';
import { selectDialogType } from '../slices';
import { ExecuteStagesDialog } from './dialogs';

export const AppDialog: FC = () => {
  const appDialog = useAppSelector(selectDialogType);

  switch (appDialog) {
    case 'execute-stages':
      return <ExecuteStagesDialog />;
    default:
      return null;
  }
};

import { FC } from 'react';

import { Divider, Typography } from '@mui/material';

import { tss } from 'tss-react/mui';

import { useAppSelector } from '../hooks/useAppSelector';
import { selectApplicationIdsByServerId } from '../hooks/useApplications';
import { Application } from './Application';

const useStyles = tss.create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

type ApplicationListProps = {
  serverId: string;
};

export const ApplicationList: FC<ApplicationListProps> = ({ serverId }) => {
  const { classes } = useStyles();
  const applicationIds = useAppSelector((state) => selectApplicationIdsByServerId(state, serverId));

  return (
    <div className={classes.root}>
      <Divider />

      <Typography variant='h6'>Registered Applications</Typography>

      {applicationIds.map((id) => (
        <Application key={id} id={id} />
      ))}
    </div>
  );
};

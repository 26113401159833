import { FC, MouseEvent, useState } from 'react';

import { Clear, DarkMode, LightMode, Login, Logout } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { tss } from 'tss-react/mui';

import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { useLogin } from '../hooks/useLogin';
import { useLogout } from '../hooks/useLogout';
import { selectTheme, toggleTheme } from '../slices';
import { selectIsAuthenticated, selectUser } from '../slices/auth.slice';
import { persistor } from '../store';

const useStyles = tss.create(({ theme }) => ({
  avatar: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    boxShadow: theme.shadows[3],
  },
  profile: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    margin: theme.spacing(0, 1, 1, 1),
  },
  profileAvatar: {
    width: 48,
    height: 48,
    boxShadow: theme.shadows[2],
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export const Profile: FC = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUser);
  const theme = useAppSelector(selectTheme);

  const login = useLogin();
  const logout = useLogout();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    login();
  };

  const handleLogout = () => {
    logout();
  };

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  return isAuthenticated ? (
    <>
      <Avatar src={user?.picture} alt={user?.name} onClick={handleOpenMenu} className={classes.avatar} />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className={classes.profile}>
          <Avatar src={user?.picture} alt={user?.name} className={classes.profileAvatar} />

          <div>
            <Typography variant='body2'>{user?.name}</Typography>
            <Typography variant='subtitle2'>{user?.email}</Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <MenuItem onClick={handleToggleTheme}>
          <ListItemIcon>{theme === 'light' ? <DarkMode /> : <LightMode />}</ListItemIcon>
          <ListItemText primary={theme === 'light' ? 'Dark Mode' : 'Light Mode'} />
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>

          <ListItemText primary='Logout' />
        </MenuItem>

        {import.meta.env.DEV && (
          <MenuItem onClick={() => persistor.purge()}>
            <ListItemIcon>
              <Clear />
            </ListItemIcon>

            <ListItemText primary='Clear Local Storage' />
          </MenuItem>
        )}
      </Menu>
    </>
  ) : (
    <Button color='inherit' variant='outlined' startIcon={<Login />} onClick={handleLogin}>
      Login
    </Button>
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { useFindAllServersQuery } from '../apis/generated-lotus.api';
import { ServerState, lotusEndpoints, serverAdapter, serverSelectors } from '../apis/lotus.api';
import { selectIsAuthenticated } from '../slices/auth.slice';
import { RootState } from '../store';
import { useAppSelector } from './useAppSelector';

export const useFindAllServers = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return useFindAllServersQuery(undefined, { skip: !isAuthenticated });
};

export const useServersSelector = <T extends (products: ServerState) => ReturnType<T>>(selector: T) => {
  // @ts-expect-error - Redux types bad: https://github.com/reduxjs/redux-toolkit/issues/3989
  return lotusEndpoints.findAllServers.useQueryState<ReturnType<T>>(undefined, {
    selectFromResult: (result) => selector(result.data ?? serverAdapter.getInitialState()),
  });
};

export const selectServers = lotusEndpoints.findAllServers.select(undefined);

export const selectServerIdsByServiceNodeId = createSelector(
  (state: RootState) => selectServers(state).data,
  (_state: RootState, serviceNodeId: string) => serviceNodeId,
  (serverState, serviceNodeId) => {
    if (!serverState) {
      return [];
    }

    return serverSelectors
      .selectAll(serverState)
      .filter((server) => server.serviceNodeId === serviceNodeId)
      .map((server) => server.id);
  },
);

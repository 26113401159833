import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { selectAccesToken } from '../slices/auth.slice';
import { RootState } from '../store';

const baseUrl = import.meta.env.VITE_LOTUS_BASE_URL;

export const emptyLotusApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, api) => {
      const accessToken = selectAccesToken(api.getState() as RootState);

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

import { User } from '@auth0/auth0-react';
import { Draft, PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { lotusEndpoints } from '../apis/lotus.api';
import { RootState } from '../store';
import { getPermissionsFromAccessToken } from '../utils/jwt.util';
import { isAuthenticationError } from '../utils/request.util';

export type AuthState = {
  isAuthenticated: boolean;
  accessToken: string | null;
  permissions: string[];
  user?: User;
};

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  permissions: [],
  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: Draft<AuthState>, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state: Draft<AuthState>, action: PayloadAction<string>) => {
        state.isAuthenticated = true;
        state.accessToken = action.payload;
        state.permissions = getPermissionsFromAccessToken(action.payload);
      })
      .addCase(logoutThunk.pending, (state: Draft<AuthState>) => {
        state.isAuthenticated = false;
        state.accessToken = null;
        state.permissions = [];
      })
      .addMatcher(
        isAnyOf(...Object.values(lotusEndpoints).map((endpoint) => endpoint.matchRejected)),
        (state: Draft<AuthState>, action) => {
          if (isAuthenticationError(action.payload)) {
            state.isAuthenticated = false;
            state.accessToken = null;
            state.permissions = [];
          }
        },
      );
  },
});

export const selectAccesToken = (state: RootState): string | null => state.auth.accessToken;
export const selectIsAuthenticated = (state: RootState): boolean => state.auth.isAuthenticated;
export const selectPermissions = (state: RootState): string[] => state.auth.permissions;
export const selectUser = (state: RootState): User | undefined => state.auth.user;

export const { setUser } = authSlice.actions;

export const { reducerPath: authPath, reducer: authReducer } = authSlice;

type LoginArg = {
  loginFn: () => Promise<void>;
  getTokenFn: () => Promise<string>;
};

type LogoutArg = {
  logoutFn: () => Promise<void>;
};

export const loginThunk = createAsyncThunk<string, LoginArg>(
  'auth/login',
  async ({ loginFn, getTokenFn }) => {
    await loginFn();

    const accessToken = await getTokenFn();

    return accessToken;
  },
);

export const logoutThunk = createAsyncThunk<void, LogoutArg>('auth/logout', ({ logoutFn }) => {
  return logoutFn();
});

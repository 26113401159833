import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { AppTheme } from '../theme';

export type UiState = {
  theme: AppTheme;
  serviceNode: Record<string, string | null>;
};

/**
 * Remember to bump the version in the redux-persist config when changing the initial state
 */
export const initialUiState: UiState = {
  theme: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  serviceNode: {},
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUiState,
  reducers: {
    toggleTheme: (state: Draft<UiState>) => {
      state.theme = state.theme === 'light' ? 'dark' : 'light';
    },
    setServiceNode: (
      state: Draft<UiState>,
      action: PayloadAction<{ productId: string; serviceNodeId: string }>,
    ) => {
      const { productId, serviceNodeId } = action.payload;

      state.serviceNode[productId] = serviceNodeId;
    },
  },
});

export const selectTheme = (state: RootState): AppTheme => state.ui.theme;
export const selectServiceNode = (state: RootState, productId: string): string | null =>
  state.ui.serviceNode[productId];

export const { toggleTheme, setServiceNode } = uiSlice.actions;

export const { name: uiPath, reducer: uiReducer } = uiSlice;

import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { DefinitionsFromApi, OverrideResultType } from '@reduxjs/toolkit/query';

import {
  Application,
  Executor,
  FindAllApplicationsApiResponse,
  FindAllExecutorsApiResponse,
  FindAllProductsApiResponse,
  FindAllServersApiResponse,
  FindAllServiceNodesApiResponse,
  FindAllStagesApiResponse,
  Product,
  SearchServersApiResponse,
  Server,
  ServiceNode,
  Stage,
  generatedLotusApi,
} from './generated-lotus.api';

const tags = ['Products', 'Service Nodes', 'Servers', 'Applications', 'Stages', 'Executors'] as const;

export const lotusApi = generatedLotusApi.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
  addTagTypes: ['Products'],
  endpoints: {
    findAllProducts: {
      providesTags: ['Products'],
      transformResponse: (response: FindAllProductsApiResponse): ProductsState => {
        return productAdapter.setAll(productAdapter.getInitialState(), response);
      },
    },
    findAllServiceNodes: {
      providesTags: ['Service Nodes'],
      transformResponse: (response: FindAllServiceNodesApiResponse): ServiceNodeState => {
        return serviceNodeAdapter.setAll(serviceNodeAdapter.getInitialState(), response);
      },
    },
    findAllServers: {
      providesTags: ['Servers'],
      transformResponse: (response: FindAllServersApiResponse): ServerState => {
        return serverAdapter.setAll(serverAdapter.getInitialState(), response);
      },
    },
    searchServers: {
      transformResponse: (response: SearchServersApiResponse): ServerState => {
        return serverAdapter.setAll(serverAdapter.getInitialState(), response);
      },
    },
    findAllApplications: {
      providesTags: ['Applications'],
      transformResponse: (response: FindAllApplicationsApiResponse): ApplicationState => {
        return applicationAdapter.setAll(applicationAdapter.getInitialState(), response);
      },
    },
    findAllStages: {
      providesTags: ['Stages'],
      transformResponse: (response: FindAllStagesApiResponse): StageState => {
        return stageAdapter.setAll(stageAdapter.getInitialState(), response);
      },
    },
    findAllExecutors: {
      providesTags: ['Executors'],
      transformResponse: (response: FindAllExecutorsApiResponse): ExecutorState => {
        return executorAdapter.setAll(executorAdapter.getInitialState(), response);
      },
    },
  },
});

export const {
  reducerPath: lotusReducerPath,
  reducer: lotusReducer,
  middleware: lotusMiddleware,
  endpoints: lotusEndpoints,
} = lotusApi;

export const productAdapter = createEntityAdapter<Product, string>({
  selectId: (product) => product.id,
});

export const serviceNodeAdapter = createEntityAdapter<ServiceNode, string>({
  selectId: (serviceNode) => serviceNode.id,
});

export const serverAdapter = createEntityAdapter<Server, string>({
  selectId: (server) => server.id,
});

export const applicationAdapter = createEntityAdapter<Application, string>({
  selectId: (application) => application.id,
});

export const stageAdapter = createEntityAdapter<Stage, string>({
  selectId: (stage) => stage.id,
});

export const executorAdapter = createEntityAdapter<Executor, string>({
  selectId: (executor) => executor.id,
});

export const productsSelectors = productAdapter.getSelectors();
export const serviceNodeSelectors = serviceNodeAdapter.getSelectors();
export const serverSelectors = serverAdapter.getSelectors();
export const applicationSelectors = applicationAdapter.getSelectors();
export const stageSelectors = stageAdapter.getSelectors();
export const executorSelectors = executorAdapter.getSelectors();

export const { useQueryState: useFindAllProductsQueryState } = lotusEndpoints.findAllProducts;
export const { useQueryState: useFindAllServiceNodesQueryState } = lotusEndpoints.findAllServiceNodes;
export const { useQueryState: useSearchServersQueryState } = lotusEndpoints.searchServers;
export const { useQueryState: useFindAllServersQueryState } = lotusEndpoints.findAllServers;
export const { useQueryState: useFindAllApplicationsQueryState } = lotusEndpoints.findAllApplications;
export const { useQueryState: useFindAllStagesQueryState } = lotusEndpoints.findAllStages;
export const { useQueryState: useFindAllExecutorsQueryState } = lotusEndpoints.findAllExecutors;

export type ProductsState = EntityState<Product, string>;
export type ServiceNodeState = EntityState<ServiceNode, string>;
export type ServerState = EntityState<Server, string>;
export type ApplicationState = EntityState<Application, string>;
export type StageState = EntityState<Stage, string>;
export type ExecutorState = EntityState<Executor, string>;

type Definitions = DefinitionsFromApi<typeof generatedLotusApi>;
type TagTypes = (typeof tags)[number];

type UpdatedFindAllProductsDefinition = OverrideResultType<Definitions['findAllProducts'], ProductsState>;
type UpdatedFindAllServiceNodesDefinition = OverrideResultType<
  Definitions['findAllServiceNodes'],
  ServiceNodeState
>;

type UpdatedFindAllServersDefinition = OverrideResultType<Definitions['findAllServers'], ServerState>;
type UpdatedSearchServersDefinition = OverrideResultType<Definitions['searchServers'], ServerState>;

type UpdatedFindAllApplicationsDefinition = OverrideResultType<
  Definitions['findAllApplications'],
  ApplicationState
>;

type UpdatedFindAllStagesDefinition = OverrideResultType<Definitions['findAllStages'], StageState>;

type UpdatedFindAllExecutorsDefinition = OverrideResultType<Definitions['findAllExecutors'], ExecutorState>;

type UpdatedDefinitions = Omit<
  Definitions,
  | 'findAllProducts'
  | 'findAllServiceNodes'
  | 'findAllServers'
  | 'searchServers'
  | 'findAllApplications'
  | 'findAllStages'
  | 'findAllExecutors'
> & {
  findAllProducts: UpdatedFindAllProductsDefinition;
  findAllServiceNodes: UpdatedFindAllServiceNodesDefinition;
  findAllServers: UpdatedFindAllServersDefinition;
  searchServers: UpdatedSearchServersDefinition;
  findAllApplications: UpdatedFindAllApplicationsDefinition;
  findAllStages: UpdatedFindAllStagesDefinition;
  findAllExecutors: UpdatedFindAllExecutorsDefinition;
};
